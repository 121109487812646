//  Core

@use "sass:math";

.ribbon-vertical {
  position: absolute;
  width: 60px;
  padding: math.div($spacer, 1.5) 0 ($spacer * 0.5);
  top: -6px;
  left: ($spacer * 1.5);
  text-align: center;
  border-top-left-radius: 3px;
  z-index: 8;
  font-weight: bold;
  box-shadow: $box-shadow;

  &--right {
    left: auto;
    right: ($spacer * 1.5);
  }

  &:before {
    height: 0;
    width: 0;
    right: -5.5px;
    top: 0.1px;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  &:before, &:after {
    content: "";
    position: absolute;
  }

  &:after {
    height: 0;
    width: 0;
    bottom: -29.5px;
    left: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid transparent;
  }

  @each $color, $value in $theme-colors {
    &--#{$color} {
      background: $value;
      color: color-yiq($value);

      &:before {
        border-bottom-color: darken($value, 20%);
      }

      &:after {
        border-left-color: $value;
        border-right-color: $value;
      }
    }
  }

  &.btn {
    border-radius: 0;
    border: 0;
  }
}
