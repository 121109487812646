.MuiAccordion-root{
    margin-bottom: 10px;
}

.tableContainer{
    height: 100%;
}

.nodata{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.header-title{
    width: 100px;
    text-align: right;
    font-weight: 700 !important;
}