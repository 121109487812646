.sortableItem{
    margin: 10px;
    padding: 10px;
    background-color: hsl(0, 0%, 90%);
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    position: relative;
}

.removeIcon{
    position: absolute;
    color: rgba(0, 0, 0, 0.87);
    right: 21px;
    top: 7px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    cursor: pointer;
}

.removeIcon:hover{
    background-color: #FFBDAD;
    color: #DE350B;
}
