.notificationbutton{
    animation: blinkingBG 2s linear infinite;
}

@keyframes blinkingBG {
    0%, 49%{
        background-color: rgba(242, 240, 240, 0.297);
    }
    50%, 100%{
        background-color: transparent;
    }
   
}