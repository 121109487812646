.cardSize {
  width: 570px;
  height: auto;
}

@media screen and (max-width: 720px) {
  .cardSize {
    width: 570px;
    height: auto;
  }
}
@media screen and (max-width: 640px) {
  .cardSize {
    width: 460px;
    height: auto;
  }
}
@media screen and (max-width: 480px) {
  .cardSize {
    width: 300px;
    height: auto;
  }
}

@media screen and (max-width: 360px) {
  .cardSize {
    width: 240px;
    height: auto;
  }
}
